import { render, staticRenderFns } from "./register.vue?vue&type=template&id=db9f00fa&scoped=true"
import script from "./register.vue?vue&type=script&lang=js"
export * from "./register.vue?vue&type=script&lang=js"
import style0 from "../login/css/login.styl?vue&type=style&index=0&id=db9f00fa&prod&lang=stylus&scoped=true&external"
import style1 from "./css/register.styl?vue&type=style&index=1&id=db9f00fa&prod&lang=stylus&scoped=true&external"
import style2 from "./css/register_en.styl?vue&type=style&index=2&id=db9f00fa&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db9f00fa",
  null
  
)

export default component.exports