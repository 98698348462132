<template>
	<div class="outer-box" :style="{ backgroundImage: bigImg }" :class="{ en: internationalKey === 'en-US' }">
		<!-- 避免自动填充表单 -->
		<input type="text" style="display:none" />
		<input type="password" style="display:none" />
		<div class="inner-box">
			<div class="inner-box_left" :style="{ backgroundImage: smallImg }"></div>
			<div class="inner-box_right register">
				<!-- 国际化 -->
				<div class="login-international" v-if="isI18nFlag" @mouseenter="setUpLanguageEnter()" @mouseleave="setUpLanguageLeave()">
					<span class="fl">
						{{ $t(internationalName) }}
					</span>
					<img src="../../../assets/images/common/down.png" alt="" class="international-img" />
					<ul v-show="setUpLanguageShow" @mouseenter="setUpLanguageEnter()" @mouseleave="setUpLanguageLeave()">
						<li v-for="item in international" :key="item.key" @click="selectChange(item.key, item.val)">
							<span>{{ $t(item.val) }}</span>
						</li>
					</ul>
				</div>
				<template v-if="!isRegistSuccess">
					<ul class="register tabs">
						<li
							:class="{
								highlight: activeName === 'phone',
							}"
						>
							{{ $t('WDNPC_WEB_MOBILE_PHONE_NUMBER_REGISTRATION', { text: '手机号注册' }) }}
						</li>
						<!-- <li
							@click="setActiveName('mail')"
							:class="{
								highlight: activeName === 'mail',
							}"
						>
							$t('WDNPC_WEB_EMAIL_REGISTRATION',{text:'邮箱注册'})
						</li> -->
					</ul>
					<div class="form">
						<el-form ref="form" :rules="rules" :model="formData">
							<el-form-item prop="account">
								<el-input
									class="phoneInput"
									:placeholder="
										activeName == 'phone'
											? $t('WDNPC_WEB_PLEASE_ENTER_YOUR_MOBILE_PHONE_NUMBER', { text: '请输入手机号' })
											: $t('WDNPC_WEB_ENTER_AN_EMAIL_ACCOUNT', { text: '请输入邮箱账号' })
									"
									v-model.trim="formData.account"
								>
									<el-select
										v-if="isI18nFlag"
										v-model="code"
                                        @change="handleSelectChange"
										slot="prepend"
										style="width: 130px"
										class="phonePrepend"
										placeholder="$t('WDNPC_WEB_PLEASE_SELECT',{text:'请选择'})"
									>
										<el-option
											:label="internationalKey === 'zh-CN' ? item.cn + ' +' + item.mobile_prefix : item.en + ' +' + item.mobile_prefix"
											:value="item.code"
											v-for="item in contryList"
											:key="item.code"
										></el-option>
									</el-select>
								</el-input>
							</el-form-item>
							<el-form-item v-if="activeName === 'phone'" prop="authCode">
								<el-input
									:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })"
									v-model.trim="formData.authCode"
								>
									<template v-slot:append>
										<el-button @click="sendCode('phone')" round
											>{{ 0 >= Countdown1 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown1 + 's' }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item v-else prop="authCode">
								<el-input :placeholder="$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })" v-model="formData.authCode">
									<template v-slot:append>
										<el-button round @click="sendCode('mail')">
											{{ 0 >= Countdown2 ? $t('WDNPC_WEB_OBTAIN_VERIFICATION_CODE', { text: '获取验证码' }) : Countdown2 + 's' }}
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item prop="password">
								<el-input
									clearable
									show-password
									:readonly="isReadonly1"
									@focus="handleFocus(1)"
									type="password"
									:placeholder="
										$t('WDNPC_WEB_PLEASE_SET_THE_PASSWORD_OF_THE_COMBINATION', { text: '请设置8-20位，数字、大小写字母、字符组合密码' })
									"
									v-model.trim="formData.password"
								>
								</el-input>
							</el-form-item>
							<el-form-item class="rePassword" prop="rePassword">
								<el-input
									:readonly="isReadonly2"
									@focus="handleFocus(2)"
									clearable
									show-password
									:placeholder="$t('WDNPC_WEB_PLEASE_ENTER_THE_LOGIN_PASSWORD_AGAIN', { text: '请再次输入登录密码' })"
									:type="passw"
									v-model.trim="formData.rePassword"
								>
								</el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="read">
						<el-checkbox v-model="hasreaded">{{ $t('WDNPC_WEB_I_HAVE_READ_AND_AGREE', { text: '我已阅读并同意' }) }}</el-checkbox>
						<div class="read_right">
							<router-link class="agreement" :to="{ path: '/userAgreement' }" target="_blank">{{
								$t('WDNPC_WEB_USER_AGREEMENT', { text: '用户协议' })
							}}</router-link>
							<span style="color: #666">{{ $t('WDNPC_WEB_AND', { text: '及' }) }}</span>
							<router-link class="agreement" :to="{ path: '/privacyPolicy' }" target="_blank">{{
								$t('WDNPC_WEB_LEGAL_STATEMENT_AND_PRIVACY_POLICY', { text: '法律声明及隐私政策' })
							}}</router-link>
						</div>
					</div>
					<div class="btn">
						<el-button class="regist" @click="onSubmit" :disabled="disabled()" :style="registBtnStyle" size="small"
							>{{ $t('WDNPC_WEB_REGISTER_NOW', { text: '立即注册' }) }}
						</el-button>
						<span>{{ $t('WDNPC_WEB_EXISTING_ACCOUNT', { text: '已有账号？' }) }}</span>
						<router-link class="toLogin" :to="{ path: '/login_new' }">{{ $t('WDNPC_WEB_LOG_ON_NOW', { text: '立即登录' }) }} ></router-link>
					</div>
				</template>
				<template v-else>
					<div class="middle">
						<div class="registTitle">{{ $t('WDNPC_WEB_CONGRATULATIONS_ON_YOUR_SUCCESSFUL_REGISTRATION', { text: '恭喜您，注册成功' }) }}！</div>
						<div class="registTip">{{ $t('WDNPC_WEB_PLEASE_KEEP_YOUR_ACCOUNT_INFORMATION_PROPERLY', { text: '请妥善保管您的账户信息' }) }}</div>
						<div class="toHome">{{ $t('WDNPC_WEB_JUMPING_TO_HOME_PAGE', { text: '正在跳转首页' }) }}…{{ backCount }}s</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { isPhone, isEmail, passReg } from '@/utils/validator'
import contryList from '../../../utils/country'
import getLanguageData from '../../../locale/locale'

export default {
	name: 'register',
	data() {
		return {
			smallImg: '',
			bigImg: '',
			isReadonly1: true,
			isReadonly2: true, //避免弹出密码提示框
			passw: 'password',
			activeName: 'phone',
			Countdown1: 0,
			Countdown2: 0,
			formData: {
				account: null,
				authCode: null,
				password: null,
				rePassword: null,
			},
			isRegistSuccess: false,
			hasreaded: false,
			backCount: 3,
			accountType: 'phone',
            mobile_prefix: '86',
            code:'156',
			countryList: [],
			isI18nFlag: false, //判断是否是国际化项目
			internationalName: '中文',
			internationalKey: 'zh-CN',
			setUpLanguageTimeout: null,
			setUpLanguageShow: false,
			rules: {
				account: [{ validator: this.accountValidator, trigger: 'blur' }],
				authCode: [
					// {
					// 	required: true,
					// 	message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' }),
					// 	trigger: 'blur',
					// },
					// {
					// 	min: 6,
					// 	max: 6,
					// 	message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' }),
					// 	trigger: 'blur',
                    // },
                    {
						validator: this.authcodeValidator,
						trigger: 'blur',
					},
				],
				password: [
					// {
					// 	required: true,
					// 	message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
					// 	trigger: 'blur',
					// },
					{ validator: this.passwordValidator, trigger: 'blur' },
				],
				rePassword: [
					// {
					// 	required: true,
					// 	message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
					// 	trigger: 'blur',
                    // },
                    { validator: this.repasswordValidator, trigger: 'blur' },
				],
			},
		}
	},
	computed: {
		registBtnStyle() {
			return {
				background: this.disabled() ? '#BFC8D9' : '#316fff',
				border: 'none',
			}
		},
		frontendConfig() {
			return this.$store.state.dynamic.frontendConfig
		},
		saasUcode() {
			let pageSetData = this.$store.state.dynamic.pageSetData
			return pageSetData && pageSetData.solutionInfo && pageSetData.solutionInfo.saasUcode
		},
		// rules() {
		// 	return {
		// 		account: [{ validator: this.accountValidator, trigger: 'blur' }],
		// 		authCode: [
		// 			{
		// 				required: true,
		// 				message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' }),
		// 				trigger: 'blur',
		// 			},
		// 			{
		// 				min: 6,
		// 				max: 6,
		// 				message: this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' }),
		// 				trigger: 'blur',
		// 			},
		// 		],
		// 		password: [
		// 			{
		// 				required: true,
		// 				message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
		// 				trigger: 'blur',
		// 			},
		// 			{ validator: this.passwordValidator, trigger: 'blur' },
		// 		],
		// 		rePassword: [
		// 			{
		// 				required: true,
		// 				message: this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' }),
		// 				trigger: 'blur',
		// 			},
		// 		],
		// 	}
		// },
		international() {
			return [
				{ key: 'zh-CN', val: '中文' }, //中文
				{ key: 'en-US', val: 'English' }, //英语
				{ key: "fr-FR", val: 'Français' }//法语
			]
		},
	},
	created() {
		localStorage.removeItem('token')
        this.isI18nFlag = localStorage.getItem('is_i18n') === 'true'
		this.contryList = contryList
		// this.initConfig()
	},
	mounted() {
        if (localStorage.getItem('current_language')) {
			let innertational = this.international.find((item) => item.key == localStorage.getItem('current_language'))
			if (innertational) {
				this.internationalName = innertational.val
                this.internationalKey = innertational.key
                document.getElementsByTagName('html')[0].setAttribute('lang', this.internationalKey)
			}
		}
    },
	methods: {
        handleSelectChange(val){
            this.mobile_prefix = this.contryList.find(item => item.code === val).mobile_prefix
        },
		// 切换语言
		setUpLanguageEnter() {
			clearTimeout(this.setUpLanguageTimeout)
			this.setUpLanguageShow = true
		},
		setUpLanguageLeave() {
			this.setUpLanguageTimeout = setTimeout(() => {
				this.setUpLanguageShow = false
			}, 300)
		},
		async selectChange(key, value) {
            this.$refs.form.clearValidate()
			console.log(key, value, 'key', 'value', this.$t(value))
			localStorage.setItem('current_language', key)
			await getLanguageData()
			this.$i18n.locale = key
			this.internationalKey = key
            this.internationalName = value
            document.getElementsByTagName('html')[0].setAttribute('lang', this.internationalKey)
			this.setUpLanguageTimeout = setTimeout(() => {
				this.setUpLanguageShow = false
			}, 100)
		},
		accountValidator(rule, value, callback) {
			if (isPhone.test(value) || isEmail.test(value)) {
				callback()
			} else {
				let message = ''
				if (this.activeName === 'phone') {
					message = this.formData.account
						? this.$t('WDNPC_WEB_PLEASE_ENTER_THE_CORRECT_MOBILE_PHONE_NUMBER', { text: '请输入正确的手机号' })
						: this.$t('WDNPC_WEB_PLEASE_ENTER_YOUR_MOBILE_PHONE_NUMBER', { text: '请输入手机号' })
				} else {
					message = this.formData.account
						? this.$t('WDNPC_WEB_ENTER_THE_CORRECT_EMAIL_ACCOUNT', { text: '请输入正确的邮箱账号' })
						: this.$t('WDNPC_WEB_ENTER_AN_EMAIL_ACCOUNT', { text: '请输入邮箱账号' })
				}
				callback(new Error(message))
			}
        },
        authcodeValidator(rule, value, callback){
            if (!value) {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_ENTER_A_VERIFICATION_CODE', { text: '请输入验证码' })))
			} else if(value.length != 6) {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_ENTER_A_SIXDIGIT_VERIFICATION_CODE', { text: '请输入六位验证码' })))
			} else {
                callback()
            }
        },
		passwordValidator(rule, value, callback) {
            if (!value) {
                callback(new Error(this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' })))
            } else if (passReg.test(value)) {
				callback()
			} else {
				callback(new Error(this.$t('WDNPC_WEB_PLEASE_SET_THE_PASSWORD_OF_THE_COMBINATION', { text: '请设置8-20位，数字、大小写字母、字符组合密码' })))
			}
        },
        repasswordValidator(rule, value, callback){
            if (!value) {
                callback(new Error(this.$t('WDNPC_WEB_THE_LOGON_PASSWORD_CANNOT_BE_EMPTY', { text: '登录密码不能为空' })))
            } else {
				callback()
			}
        },
		initConfig() {
			if (this.frontendConfig.length > 0) {
				localStorage.setItem('configurationArr', JSON.stringify(this.frontendConfig))
				this.frontendConfig.forEach((item) => {
					switch (item.key) {
						case 'is_i18n':
							if (item.value === 'true') {
								this.isI18nFlag = true
							} else {
								this.isI18nFlag = false
							}
							localStorage.setItem('is_i18n', item.value)
							break
						case 'custom_login_background_img':
							if (item.value) {
								this.bigImg = item.value
								localStorage.setItem('custom_login_background_img', item.value)
							}
							break
						case 'custom_login_img':
							if (item.value) {
								this.smallImg = item.value
								localStorage.setItem('custom_login_img', item.value)
							}
							break
						default:
							break
					}
				})
			}
		},
		handleFocus(val) {
			setTimeout(() => {
				if (val === 1) {
					this.isReadonly1 = false
					this.isReadonly2 = true
				} else {
					this.isReadonly2 = false
					this.isReadonly1 = true
				}
			})
		},
		disabled() {
			let result = false
			for (let key in this.formData) {
				if (!this.formData[key]) {
					result = true
					break
				}
			}
			if (!this.hasreaded) {
				result = true
			}
			return result
		},
		setActiveName(val) {
			this.activeName = val
			this.$refs.form.resetFields()
			this.formData.authCode = null
		},
		async onSubmit() {
			if (!this.checkoutForm()) return
			this.toRegister()
		},
		// 校验表单
		checkoutForm() {
			let result = true
			this.$refs.form.validate((valid) => {
				if (valid) {
					if (!this.hasreaded || !this.handleBlur()) {
						result = false
					}
				} else {
					result = false
				}
			})
			return result
		},
		sendCode(type) {
			let CountdownType = type === 'phone' ? 'Countdown1' : 'Countdown2'
			if (this[CountdownType] > 0) return
			this.formData.authCode = null
			this.$refs.form.validateField('account', (err) => {
				if (!err && type === 'phone') {
					this.Countdown1 = 60
					this.setCountdown(type)
					this.$api.account_new.getPhoneAuthCode4Register({ phone: this.formData.account, areaCode: this.mobile_prefix })
				} else if (!err && type === 'mail') {
					// this.Countdown2 = 60
					// this.setCountdown(type)
				}
			})
		},
		async toRegister() {
			let data = { ...this.formData, areaCode: this.mobile_prefix, saasUcode: this.saasUcode }
			if (this.activeName === 'phone') {
				data.phone = this.formData.account
			} else if (this.activeName === 'mail') {
				data.mail = this.formData.account
			}
			console.log('---------------', this.saasUcode)
			this.$api.account_new.register(data).then((res) => {
				if (res.success) {
					this.$message.success(this.$t('WDNPC_WEB_REGISTERED_SUCCESSFULLY', { text: '注册成功' }))
					this.$router.replace('/login_new')
				}
			})
		},
		setCountdown(type) {
			let CountdownType = type === 'phone' ? 'Countdown1' : 'Countdown2'
			let Countdown = null
			Countdown = setInterval(() => {
				this[CountdownType]--
				if (this[CountdownType] <= 0) {
					clearInterval(Countdown)
				}
			}, 1000)
		},
		handleBlur() {
			let result = true
			if (this.formData.password && this.formData.rePassword) {
				if (this.formData.password !== this.formData.rePassword) {
					this.$message.error(this.$t('WDNPC_WEB_INCONSISTENT_PASSWORD_INPUT', { text: '两次密码输入不一致' }))
					result = false
				}
			}
			return result
		},
	},
}
</script>

<style lang="stylus" scoped src="../login/css/login.styl"></style>
<style lang="stylus" scoped src="./css/register.styl"></style>
<style lang="stylus" scoped src="./css/register_en.styl"></style>
